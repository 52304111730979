import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../public/css/style.css';
// import VueSplide from '@splidejs/vue-splide';

import * as VueGoogleMaps from "vue2-google-maps";
// import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'leaflet/dist/leaflet.css';
import { Icon } from "leaflet";
// import mobiscroll  from '../lib/js/mobiscroll.javascript.min.js';
// import './index.css'


delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBEyzmxyOvHKdFEsQuurDvfb7JhttatY7E",
    libraries: "places"
  }
  });

// Vue.use(VueSplide);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)


new Vue({
  // mbsc: mobiscroll,
  router,
  render: h => h(App),
}).$mount('#app')
