import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/hotel/:id",
      alias: "/hotel",
      name: "hotel",
      component: () => import('../components/home')
    },
   

    { path: "*", component: () => import('../components/pageNotFound')},
  
  
  ]
});